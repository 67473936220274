import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetConditionPipe } from './get-condition.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [GetConditionPipe],
  exports: [GetConditionPipe]
})
export class GetConditionModule {}
