import { Pipe, PipeTransform } from '@angular/core';
import { TravelConditionDTO } from '@gtd/api-services/b2b-client';

@Pipe({
  name: 'getCondition'
})
export class GetConditionPipe implements PipeTransform {
  transform(
    value: Array<TravelConditionDTO>,
    supplierType: 'AIR' | 'HOTEL'
  ): any {
    return value.filter(condition => condition.supplierType === supplierType);
  }
}
